import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import type {Store} from '../../lib/StoreType';
import {SouhlasFormData} from '../types';

export type CommonStore = {
    readonly activeStep: number;
    readonly formData: Partial<SouhlasFormData> | undefined;
    readonly formSuccessSteps: {[key: number]: boolean} | undefined;
    readonly endOfForm: boolean;
};

const initialState: CommonStore = {
    activeStep: 0,
    formData: undefined,
    formSuccessSteps: undefined,
    endOfForm: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFormData: (state: CommonStore, {payload}: PayloadAction<Partial<SouhlasFormData>>): CommonStore => {
            return {...state, formData: {...state.formData, ...payload}};
        },
        setActiveStep: (state: CommonStore, {payload}: PayloadAction<number>): CommonStore => {
            return {...state, activeStep: payload};
        },
        setSuccessStep: (state: CommonStore, {payload}: PayloadAction<number>): CommonStore => {
            return {...state, formSuccessSteps: {...state.formSuccessSteps, [payload]: true}};
        },
        setEndForm: (state: CommonStore): CommonStore => {
            return {...state, endOfForm: true};
        },
    },
});

export const {setActiveStep, setFormData, setSuccessStep, setEndForm} = commonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const common = (state: Store) => state.common;

export default commonSlice.reducer;
