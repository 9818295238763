import {useDispatch} from 'react-redux';
import {RootActionType} from '../../lib/rootAction';

import {setActiveStep, setEndForm, setFormData, setSuccessStep} from '../reducers/CommonSlice';
import {SouhlasFormData} from '../types';

type CommonActionType = {
    setEndOfForm: () => void;
    resetFormData: () => void;
    setFormData: (data: Partial<SouhlasFormData>) => void;
    setActiveStep: (step: number) => void;
    setSuccessStep: (step: number) => void;
};

/**
 * It returns an object with functions that dispatch actions to the Redux store
 */
export const useCommonAction = (): CommonActionType => {
    const dispatch = useDispatch();

    return {
        resetFormData: () => {
            dispatch({type: RootActionType.LOGOUT, payload: undefined});
        },
        setEndOfForm: () => {
            dispatch(setEndForm());
        },

        setFormData: (data) => {
            dispatch(setFormData(data));
        },
        setActiveStep: (step) => {
            dispatch(setActiveStep(step));
        },
        setSuccessStep(step) {
            dispatch(setSuccessStep(step));
        },
    };
};
