import {gql} from '@apollo/client/core';

export const CommonQueries = {
    gql: {
        odstaveniFormulare: gql`
            query OdstaveniFormulare {
                vyjadrovaciLinka {
                    odstavka {
                        id
                        aktivni
                        formZakazanHtml
                        typFormulare
                    }
                }
            }
        `,
        ares: gql`
            query Ares($ic: String!) {
                ares {
                    companyDataByIco(ic: $ic) {
                        nazevFirmy
                        adresa {
                            ulice
                            cisloEvidencni
                            cisloOrientacni
                            cisloPopisne
                            obec
                            mistniCast
                            psc
                        }
                    }
                }
            }
        `,

        om: gql`
            query NaseptavacOdbernaMista($input: String!, $parseId: String!) {
                adresniMista {
                    fulltextAdresniMista(
                        first: 5
                        filter: {adresa: $input, fulltextFields: [ulice, cisloEvidencni, cisloOrientacni, cisloPopisne, psc, mesto, mistniCast]}
                        parseId: $parseId
                    ) {
                        edges {
                            node {
                                id
                                cisloEvidencni
                                ulice
                                cisloOrientacni
                                cisloPopisne
                                mesto
                                mistniCast
                                psc
                            }
                        }
                    }
                }
            }
        `,
        zadost: gql`
            query Zadost($id: MongoObjectId!) {
                souhlas {
                    zadost(id: $id) {
                        technickaSpecifikace {
                            prilohy {
                                id
                                mime
                                nazev
                                referenceId
                                velikost
                                vytvoreno
                            }
                            elektrina
                            plyn
                            elektrinaPuvodne
                            plynPuvodne
                        }
                        elektrinaIncidence
                        plynIncidence
                    }
                }
            }
        `,
        prilohy: gql`
            query PrilohySouhrn($id: MongoObjectId!) {
                souhlas {
                    zadost(id: $id) {
                        technickaSpecifikace {
                            prilohy {
                                id
                                mime
                                nazev
                                referenceId
                                velikost
                                vytvoreno
                            }
                        }
                    }
                }
            }
        `,
    },
};
